import { IconLayoutDashboard } from '@tabler/icons-react';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = ({ orgID }) => ({
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: `/app/${orgID}/dashboard`,
      icon: IconLayoutDashboard,
      breadcrumbs: false,
    },
  ],
});

export default dashboard;
