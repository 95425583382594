import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  databases: [],
  dbInfo: [],
  error: null,
  success: false,
  errorMessage: null,
};

export const databaseGet = createAsyncThunk('database/get', async ({ queryString }, { getState, rejectWithValue }) => {
  // Configure the headers for the axios request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Access the token from the account slice using getState
      Authorization: getState().account.token,
    },
  };

  try {
    // Make the axios GET request
    const response = await axios.get(`/api/database${queryString || ''}`, config);
    return response;
  } catch (error) {
    // Return the error response data as the payload if the request fails
    return rejectWithValue(error.response.data);
  }
});

export const databaseGetInfo = createAsyncThunk('database/getInfo', async ({ database }, { getState, rejectWithValue }) => {
  // Configure the headers for the axios request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Access the token from the account slice using getState
      Authorization: getState().account.token,
    },
  };

  try {
    // Make the axios GET request
    const response = await axios.get(`/api/database/${database}`, config);
    return response;
  } catch (error) {
    // Return the error response data as the payload if the request fails
    return rejectWithValue(error.response.data);
  }
});

export const databasePost = createAsyncThunk('database/post', async ({ payload, queryString }, { getState, rejectWithValue }) => {
  // Configure the headers for the axios request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Access the token from the account slice using getState
      Authorization: getState().account.token,
    },
  };

  try {
    // Make the axios POST request
    const response = await axios.post(`/api/database${queryString || ''}`, payload, config);
    return response;
  } catch (error) {
    // Return the error response data as the payload if the request fails
    return rejectWithValue(error.response.data);
  }
});

const databaseSlice = createSlice({
  name: 'database',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(databaseGet.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseGet.fulfilled, (state, action) => {
      state.loading = false;
      state.databases = action.payload.data;
    });

    builder.addCase(databaseGet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databaseGetInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databaseGetInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.dbInfo = action.payload.data;
    });

    builder.addCase(databaseGetInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(databasePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(databasePost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(databasePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default databaseSlice.reducer;
