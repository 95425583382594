import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import axios from 'api';

// initialize userToken from local storage
const isAuthenticated = localStorage.getItem('isAuthenticated') ? localStorage.getItem('isAuthenticated') : false;
const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : { role: '' };

const initialState = {
  loading: false,
  account: user, // for user object
  token,
  isAuthenticated,
  success: false, // for monitoring the registration process.
};

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// Generates pending, fulfilled and rejected action types
export const accountRegister = createAsyncThunk('account/register', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/api/user/register`, payload, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

export const accountLogin = createAsyncThunk('account/login', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/api/user/login`, { email: payload.email, password: payload.password }, config);
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    if (payload.rememberMe) {
      // Save the JWT token and user information in cookies
      Cookies.set('isAuthenticated', true, { expires: 7 });
      Cookies.set('token', response.data.token, { expires: 7 });
      Cookies.set('user', JSON.stringify(response.data.user), { expires: 7 });
    }
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

export const accountGetDetails = createAsyncThunk('account/getDetails', () =>
  axios
    .get(`/api/user/profile`, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.account = {
        role: '',
      };
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('token'); // deletes token from storage
      localStorage.removeItem('user'); // deletes user from storage
      localStorage.removeItem('organizations'); // deletes organizations from storage
      Cookies.remove('isAuthenticated');
      Cookies.remove('token');
      Cookies.remove('user');
      Cookies.remove('organizations');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(accountRegister.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountRegister.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(accountRegister.rejected, (state) => {
      state.loading = false;
      state.success = false;
    });

    builder.addCase(accountLogin.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload.token;
      state.account = action.payload.user;
      state.isAuthenticated = true;
    });

    builder.addCase(accountLogin.rejected, (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.success = false;
    });

    builder.addCase(accountGetDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(accountGetDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.account = action.payload;
      state.isAuthenticated = true;
    });

    builder.addCase(accountGetDetails.rejected, (state) => {
      state.loading = false;
      state.success = false;
    });

    // builder.addCase(accountGetOrganizations.pending, (state) => {
    //   state.loading = true;
    // });

    // builder.addCase(accountGetOrganizations.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.organizations = action.payload.data;
    //   Cookies.set('organizations', JSON.stringify(action.payload.data), { expires: 7 });
    // });

    // builder.addCase(accountGetOrganizations.rejected, (state) => {
    //   state.loading = false;
    //   state.success = false;
    // });
  },
});

export const { logout } = accountSlice.actions;

export default accountSlice.reducer;
