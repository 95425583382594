import { combineReducers } from '@reduxjs/toolkit';
// import { createReducer } from '@reduxjs/toolkit';

// reducer import
import customizationReducer from './customizationReducer';
import accountSlice from '../features/account/accountSlice';
import databaseSlice from '../features/database/databaseSlice';
import dataTypeSlice from '../features/dataType/dataTypeSlice';
import deviceSlice from '../features/device/deviceSlice';
import firmwareSlice from '../features/firmware/firmwareSlice';
import locationSlice from '../features/location/locationSlice';
import organizationSlice from '../features/organization/organizationSlice';
import sourceSlice from '../features/source/sourceSlice';
import subscriberSlice from '../features/subscriber/subscriberSlice';
import topicSlice from '../features/topic/topicSlice';
import userSlice from '../features/user/userSlice';
import userPermissionSlice from '../features/userPermission/userPermissionSlice';
import userRoleSlice from '../features/userRole/userRoleSlice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  account: accountSlice,
  database: databaseSlice,
  dataType: dataTypeSlice,
  device: deviceSlice,
  firmware: firmwareSlice,
  location: locationSlice,
  organization: organizationSlice,
  source: sourceSlice,
  subscriber: subscriberSlice,
  topic: topicSlice,
  user: userSlice,
  userPermission: userPermissionSlice,
  userRole: userRoleSlice,
});

export default reducer;
