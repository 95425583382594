import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  dataTypes: {
    data: [],
  },
  error: null,
  success: false,
  errorMessage: null,
};

// Generates pending, fulfilled and rejected action types
export const dataTypeGet = createAsyncThunk('dataType/get', async ({ database, queryString }, { rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await axios.get(`/api/datatype${database !== '' ? `/${database}` : ''}${queryString || ''}`, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

export const dataTypePost = createAsyncThunk(
  'dataType/post',
  // Destructure the argument object to get the "database" and "payload" properties
  async ({ database, payload, queryString }, { getState, rejectWithValue }) => {
    // Configure the headers for the axios request
    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Access the token from the account slice using getState
        Authorization: getState().account.token,
      },
    };

    try {
      // Make the axios POST request
      const response = await axios.post(`/api/datatype${database !== '' ? `/${database}` : ''}${queryString || ''}`, payload, config);
      return response;
    } catch (error) {
      // Return the error response data as the payload if the request fails
      return rejectWithValue(error.response.data);
    }
  }
);

const dataTypeSlice = createSlice({
  name: 'dataType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(dataTypeGet.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(dataTypeGet.fulfilled, (state, action) => {
      state.loading = false;
      state.dataTypes = action.payload.data;
    });

    builder.addCase(dataTypeGet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(dataTypePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(dataTypePost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(dataTypePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default dataTypeSlice.reducer;
