import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const words = name.split(' ');

  let children = '';
  if (words.length > 0) {
    children = words[0][0]; // Use the first letter of the first word
    if (words.length > 1) {
      children += words[1][0]; // Append the first letter of the second word if available
    }
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children,
  };
}

export default function BackgroundLetterAvatar(props) {
  const { name, ...avatarProps } = props;

  return <Avatar {...stringAvatar(name)} {...avatarProps} />;
}

BackgroundLetterAvatar.propTypes = {
  name: PropTypes.string,
};
