import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  userPermissions: [],
  success: false, // for monitoring the registration process.
};

export const userPermissionGet = createAsyncThunk('userPermission/get', async ({ queryString }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Access the token from the account slice using getState
      Authorization: getState().account.token,
    },
  };
  try {
    const response = await axios.get(`/api/user/permission${queryString || ''}`, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

export const userPermissionPost = createAsyncThunk(
  'userPermission/post',
  // Destructure the argument object to get the "database" and "payload" properties
  async ({ queryString, payload }, { getState, rejectWithValue }) => {
    // Configure the headers for the axios request
    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Access the token from the account slice using getState
        Authorization: getState().account.token,
      },
    };

    try {
      // Make the axios POST request
      const response = await axios.post(`/api/user/permission${queryString || ''}`, payload, config);
      return response;
    } catch (error) {
      // Return the error response data as the payload if the request fails
      return rejectWithValue(error.response.data);
    }
  }
);

export const userPermissionDelete = createAsyncThunk('userPermission/delete', async ({ queryString }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    // data: payload, // pass the payload in the config object
  };

  try {
    const response = await axios.delete(`/api/user/permission${queryString || ''}`, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const userPermissionSlice = createSlice({
  name: 'userPermission',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userPermissionGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userPermissionGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.userPermissions = action.payload.data;
    });

    builder.addCase(userPermissionGet.rejected, (state) => {
      state.loading = false;
      state.success = false;
    });

    builder.addCase(userPermissionPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userPermissionPost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userPermissionPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(userPermissionDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userPermissionDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userPermissionDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default userPermissionSlice.reducer;
