import { IconBraces, IconDatabaseCog, IconDevices, IconLockAccess, IconMap2, IconUsers } from '@tabler/icons-react';

const admin = ({ orgID, location, device }) => {
  const menuItem = [
    {
      id: 'datatypes',
      title: 'Data Types',
      type: 'item',
      url: `/app/${orgID}/datatypes`,
      icon: IconBraces,
      breadcrumbs: true,
    },
    {
      id: 'databases',
      title: 'Databases',
      type: 'item',
      url: `/app/${orgID}/databases`,
      icon: IconDatabaseCog,
      breadcrumbs: true,
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: `/app/${orgID}/users`,
      icon: IconUsers,
      breadcrumbs: true,
    },
    {
      id: 'permissions',
      title: 'Permissions',
      type: 'item',
      url: `/app/${orgID}/permissions`,
      icon: IconLockAccess,
      breadcrumbs: true,
    },
  ];

  if (device) {
    menuItem.push({
      id: 'devices',
      title: 'Devices',
      type: 'collapse',
      url: `/app/${orgID}/devices`,
      icon: IconDevices,
      breadcrumbs: true,
      children: [
        {
          id: `device-${device.device_name || device.device_id}`,
          title: device.device_name || `Device ${device.device_id}`,
          type: 'item',
          url: `/app/${orgID}/device/${device.device_id}`,
          icon: IconDevices,
          breadcrumbs: true,
        },
      ],
    });
  } else {
    menuItem.push({
      id: 'devices',
      title: 'Devices',
      type: 'item',
      url: `/app/${orgID}/devices`,
      icon: IconDevices,
      breadcrumbs: true,
    });
  }

  if (location) {
    menuItem.push({
      id: 'locations',
      title: 'Locations',
      type: 'collapse',
      url: `/app/${orgID}/locations`,
      icon: IconMap2,
      breadcrumbs: true,
      children: [
        {
          id: `location-${location.location_name || location.location_id}`,
          title: location.location_name || `Location ${location.location_id}`,
          type: 'item',
          url: `/app/${orgID}/location/${location.location_id}`,
          icon: IconMap2,
          breadcrumbs: true,
        },
      ],
    });
  } else {
    menuItem.push({
      id: 'locations',
      title: 'Locations',
      type: 'item',
      url: `/app/${orgID}/locations`,
      icon: IconMap2,
      breadcrumbs: true,
    });
  }

  return {
    id: 'admin-group',
    title: 'Admin',
    type: 'group',
    children: menuItem,
  };
};

export default admin;
