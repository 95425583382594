import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

// initialize userRoleToken from local storage
const initialState = {
  loading: false,
  userRoles: [],
  success: false, // for monitoring the registration process.
};

export const userRoleGet = createAsyncThunk('userRole/get', async ({ queryString }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Access the token from the account slice using getState
      Authorization: getState().account.token,
    },
  };
  try {
    const response = await axios.get(`/api/user/role${queryString || ''}`, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

export const userRolePost = createAsyncThunk(
  'userRole/post',
  // Destructure the argument object to get the "database" and "payload" properties
  async ({ queryString, payload }, { getState, rejectWithValue }) => {
    // Configure the headers for the axios request
    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Access the token from the account slice using getState
        Authorization: getState().account.token,
      },
    };

    try {
      // Make the axios POST request
      const response = await axios.post(`/api/user/role${queryString || ''}`, payload, config);
      return response;
    } catch (error) {
      // Return the error response data as the payload if the request fails
      return rejectWithValue(error.response.data);
    }
  }
);

export const userRolePut = createAsyncThunk(
  'userRole/put',
  // Destructure the argument object to get the "database" and "payload" properties
  async ({ queryString, payload }, { getState, rejectWithValue }) => {
    // Configure the headers for the axios request
    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Access the token from the account slice using getState
        Authorization: getState().account.token,
      },
    };

    try {
      // Make the axios POST request
      const response = await axios.put(`/api/user/role${queryString || ''}`, payload, config);
      return response;
    } catch (error) {
      // Return the error response data as the payload if the request fails
      return rejectWithValue(error.response.data);
    }
  }
);

export const userRoleDelete = createAsyncThunk(
  'userRole/delete',
  // Destructure the argument object to get the "database" and "payload" properties
  async ({ queryString }, { getState, rejectWithValue }) => {
    // Configure the headers for the axios request
    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Access the token from the account slice using getState
        Authorization: getState().account.token,
      },
    };

    try {
      // Make the axios POST request
      const response = await axios.delete(`/api/user/role${queryString || ''}`, config);
      return response;
    } catch (error) {
      // Return the error response data as the payload if the request fails
      return rejectWithValue(error.response.data);
    }
  }
);

const userRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userRoleGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userRoleGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.userRoles = action.payload.data;
    });

    builder.addCase(userRoleGet.rejected, (state) => {
      state.loading = false;
      state.success = false;
    });

    builder.addCase(userRolePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userRolePost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userRolePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(userRolePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userRolePut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userRolePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(userRoleDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(userRoleDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(userRoleDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default userRoleSlice.reducer;
