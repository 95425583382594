import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  sources: [],
  success: false,
  error: null,
};

export const sourceGet = createAsyncThunk('source/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/source';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const sourcePost = createAsyncThunk('source/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/source';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.post(url, payload, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const sourcePut = createAsyncThunk('source/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/source';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.put(url, payload, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const sourceDelete = createAsyncThunk('source/delete', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/source';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.delete(url, config);
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const sourceSlice = createSlice({
  name: 'source',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sourceGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(sourceGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.sources = action.payload.data?.data || [];
    });

    builder.addCase(sourceGet.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.sources = [];
    });

    builder.addCase(sourcePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(sourcePost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(sourcePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(sourcePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(sourcePut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(sourcePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(sourceDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(sourceDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(sourceDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default sourceSlice.reducer;
