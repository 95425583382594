import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  locations: [],
  types: [],
  success: false,
  error: null,
};

export const locationGet = createAsyncThunk('location/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationPost = createAsyncThunk('location/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.post(url, payload, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationPut = createAsyncThunk('location/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.put(url, payload, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationDelete = createAsyncThunk('location/delete', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.delete(url, config);
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationTypeGet = createAsyncThunk('location/get/type', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/location/type';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationTypePost = createAsyncThunk(
  'location/post/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/location/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationTypePut = createAsyncThunk(
  'location/put/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/location/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationTypeDelete = createAsyncThunk(
  'location/delete/type',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/location/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.delete(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(locationGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(locationGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.locations = action.payload.data;
    });

    builder.addCase(locationGet.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.locations = [];
    });

    builder.addCase(locationPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationPost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(locationPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationPut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(locationPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(locationDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypeGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(locationTypeGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.types = action.payload.data?.data || [];
    });

    builder.addCase(locationTypeGet.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.types = [];
    });

    builder.addCase(locationTypePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationTypePost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(locationTypePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationTypePut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(locationTypePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypeDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationTypeDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(locationTypeDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default locationSlice.reducer;
