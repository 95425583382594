import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

// initialize subscriberToken from local storage
const initialState = {
  loading: false,
  subscribers: [],
  success: false, // for monitoring the registration process.
  containers: [],
  logs: '',
  stats: {},
};

export const subscriberGet = createAsyncThunk('subscriber/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/subscriber';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

export const subscriberPost = createAsyncThunk(
  'subscriber/post',
  // Destructure the argument object to get the "database" and "payload" properties
  async ({ queryString, payload }, { getState, rejectWithValue }) => {
    // Configure the headers for the axios request
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
    };

    try {
      // Make the axios POST request
      const response = await axios.post(`/api/subscriber${queryString || ''}`, payload, config);
      return response;
    } catch (error) {
      // Return the error response data as the payload if the request fails
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberPut = createAsyncThunk(
  'subscriber/put',
  // Destructure the argument object to get the "database" and "payload" properties
  async ({ queryString, payload }, { getState, rejectWithValue }) => {
    // Configure the headers for the axios request
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
    };

    try {
      // Make the axios POST request
      const response = await axios.put(`/api/subscriber${queryString || ''}`, payload, config);
      return response;
    } catch (error) {
      // Return the error response data as the payload if the request fails
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberDelete = createAsyncThunk('subscriber/delete', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/subscriber';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.delete(url, config);
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const subscriberPostContainerStart = createAsyncThunk(
  'subscriber/post/container/start',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/subscriber/start';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.post(url, payload, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberPostContainerStop = createAsyncThunk(
  'subscriber/post/container/stop',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/subscriber/stop';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.post(url, payload, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberGetContainerList = createAsyncThunk(
  'subscriber/get/container/list',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      let url = '/api/subscriber/list';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberGetContainerLogs = createAsyncThunk(
  'subscriber/get/container/logs',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      let url = '/api/subscriber/logs';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriberGetContainerStats = createAsyncThunk(
  'subscriber/get/container/stats',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      let url = '/api/subscriber/stats';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subscriberSlice = createSlice({
  name: 'subscriber',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(subscriberGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(subscriberGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.subscribers = action.payload.data;
    });

    builder.addCase(subscriberGet.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.subscribers = [];
    });

    builder.addCase(subscriberPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberPost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(subscriberPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberPut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(subscriberPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(subscriberDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberPostContainerStart.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberPostContainerStart.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(subscriberPostContainerStart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberPostContainerStop.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(subscriberPostContainerStop.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(subscriberPostContainerStop.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(subscriberGetContainerList.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(subscriberGetContainerList.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.containers = action.payload.data;
    });

    builder.addCase(subscriberGetContainerList.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.containers = [];
    });

    builder.addCase(subscriberGetContainerLogs.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(subscriberGetContainerLogs.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.logs = action.payload.data;
    });

    builder.addCase(subscriberGetContainerLogs.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.logs = '';
    });

    builder.addCase(subscriberGetContainerStats.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(subscriberGetContainerStats.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.stats = action.payload.data;
    });

    builder.addCase(subscriberGetContainerStats.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.stats = {};
    });
  },
});

export default subscriberSlice.reducer;
