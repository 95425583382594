import { lazy } from 'react';

// project import=
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

import WithAuthentication from 'components/withAuthentication';
import CheckAccess from 'components/checkAccess';
// import PartOfOrganization from '../components/partOfOrganization';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));
const Database = Loadable(lazy(() => import('views/database')));
const DataType = Loadable(lazy(() => import('views/dataType')));
const Device = Loadable(lazy(() => import('views/device')));
const DeviceInfo = Loadable(lazy(() => import('views/deviceInfo')));
const DeviceType = Loadable(lazy(() => import('views/deviceType')));
const Firmware = Loadable(lazy(() => import('views/firmware')));
const Location = Loadable(lazy(() => import('views/location')));
const LocationInfo = Loadable(lazy(() => import('views/locationInfo')));
const LocationType = Loadable(lazy(() => import('views/locationType')));
const Organization = Loadable(lazy(() => import('views/organization')));
const Permission = Loadable(lazy(() => import('views/permission')));
const Profile = Loadable(lazy(() => import('views/profile/index')));
const Subscriber = Loadable(lazy(() => import('views/subscriber')));
const SubcriberInfo = Loadable(lazy(() => import('views/subscriberInfo')));
const User = Loadable(lazy(() => import('views/user')));

// ==============================|| MAIN ROUTING ||============================== //

const OrgRoutes = {
  path: '/app/:orgID',
  element: <WithAuthentication component={MainLayout} />,
  children: [
    {
      path: 'dashboard',
      element: <CheckAccess component={DashboardDefault} access="member" />,
    },
    {
      path: 'datatypes',
      element: <CheckAccess component={DataType} access="admin" />,
    },
    {
      path: 'databases',
      element: <CheckAccess component={Database} access="admin" />,
    },
    {
      path: 'users',
      element: <CheckAccess component={User} access="admin" />,
    },
    {
      path: 'permissions',
      element: <CheckAccess component={Permission} access="admin" />,
    },
    {
      path: 'firmware',
      element: <CheckAccess component={Firmware} access="superuser" />,
    },
    {
      path: 'organizations',
      element: <CheckAccess component={Organization} access="superuser" />,
    },
    {
      path: 'subscribers',
      element: <CheckAccess component={Subscriber} access="superuser" />,
    },
    {
      path: 'subscriber/:subID',
      element: <CheckAccess component={SubcriberInfo} access="superuser" />,
    },
    {
      path: 'profile/:userID',
      element: <CheckAccess component={Profile} access="admin" />,
    },
    {
      path: 'devices',
      element: <CheckAccess component={Device} access="admin" />,
    },
    {
      path: 'device/:deviceID',
      element: <CheckAccess component={DeviceInfo} access="admin" />,
    },
    {
      path: 'devicetypes',
      element: <CheckAccess component={DeviceType} access="superuser" />,
    },
    {
      path: 'locations',
      element: <CheckAccess component={Location} access="admin" />,
    },
    {
      path: 'location/:locationID',
      element: <CheckAccess component={LocationInfo} access="admin" />,
    },
    {
      path: 'locationtypes',
      element: <CheckAccess component={LocationType} access="superuser" />,
    },
  ],
};

export default OrgRoutes;
