import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  organizations: [], // for organization object
  success: false, // for monitoring the registration process.
};

export const organizationGet = createAsyncThunk('organization/get', async ({ queryString }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Access the token from the account slice using getState
      Authorization: getState().account.token,
    },
  };
  try {
    const response = await axios.get(`/api/organization${queryString || ''}`, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

export const organizationPost = createAsyncThunk(
  'organization/post',
  // Destructure the argument object to get the "database" and "payload" properties
  async ({ queryString, payload }, { getState, rejectWithValue }) => {
    // Configure the headers for the axios request
    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Access the token from the account slice using getState
        Authorization: getState().account.token,
      },
    };

    try {
      // Make the axios POST request
      const response = await axios.post(`/api/organization${queryString || ''}`, payload, config);
      return response;
    } catch (error) {
      // Return the error response data as the payload if the request fails
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationPut = createAsyncThunk('organization/put', async ({ queryString, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
  };

  try {
    // Make the axios PUT request
    const response = await axios.put(`/api/organization${queryString || ''}`, payload, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(organizationGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(organizationGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.organizations = action.payload.data;
    });

    builder.addCase(organizationGet.rejected, (state) => {
      state.loading = false;
      state.success = false;
    });

    builder.addCase(organizationPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationPost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(organizationPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationPut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(organizationPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default organizationSlice.reducer;
