import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

const WithoutAuthentication = ({ component: Component }) => {
  const { isAuthenticated } = useSelector((state) => state.account);

  return isAuthenticated ? <Navigate to="/" replace /> : <Component />;
};

WithoutAuthentication.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default WithoutAuthentication;
