import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  devices: [],
  types: [],
  success: false,
  error: null,
};

export const deviceGet = createAsyncThunk('device/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const devicePost = createAsyncThunk('device/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.post(url, payload, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const devicePut = createAsyncThunk('device/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.put(url, payload, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deviceDelete = createAsyncThunk('device/delete', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.delete(url, config);
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deviceTypeGet = createAsyncThunk('device/get/type', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/device/type';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deviceTypePost = createAsyncThunk(
  'device/post/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceTypePut = createAsyncThunk(
  'device/put/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceTypeDelete = createAsyncThunk('device/delete/type', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/device/type';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    return await axios.delete(url, config);
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deviceMappingPost = createAsyncThunk(
  'device/post/mapping',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/mapping';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceMappingDelete = createAsyncThunk(
  'device/delete/mapping',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/mapping';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.delete(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deviceGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deviceGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.devices = action.payload.data;
    });

    builder.addCase(deviceGet.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.devices = [];
    });

    builder.addCase(devicePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(devicePost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(devicePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(devicePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(devicePut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(devicePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(deviceDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypeGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deviceTypeGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.types = action.payload.data?.data || [];
    });

    builder.addCase(deviceTypeGet.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.types = [];
    });

    builder.addCase(deviceTypePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceTypePost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(deviceTypePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceTypePut.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(deviceTypePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypeDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceTypeDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(deviceTypeDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceMappingPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceMappingPost.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(deviceMappingPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceMappingDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceMappingDelete.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(deviceMappingDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default deviceSlice.reducer;
