import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});

export default store;
