import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

// initialize userToken from local storage
const initialState = {
  loading: false,
  users: [],
  success: false, // for monitoring the registration process.
};

export const userGetAll = createAsyncThunk('user/getAll', async ({ queryString }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Access the token from the account slice using getState
      Authorization: getState().account.token,
    },
  };
  try {
    const response = await axios.get(`/api/user${queryString || ''}`, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data); // Return the error response data as the payload
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userGetAll.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userGetAll.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.users = action.payload.data;
    });

    builder.addCase(userGetAll.rejected, (state) => {
      state.loading = false;
      state.success = false;
    });
  },
});

export default userSlice.reducer;
