import { IconCpu, IconDevicesCode, IconMapPin, IconMessageDown, IconUsersGroup } from '@tabler/icons-react';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const superuser = ({ subscriber }) => {
  const menuItem = [
    {
      id: 'firmware',
      title: 'Firmware',
      type: 'item',
      url: '/app/superuser/firmware',
      icon: IconCpu,
      breadcrumbs: true,
    },
    {
      id: 'organizations',
      title: 'Organizations',
      type: 'item',
      url: '/app/superuser/organizations',
      icon: IconUsersGroup,
      breadcrumbs: true,
    },
    {
      id: 'devicetypes',
      title: 'Device Types',
      type: 'item',
      url: `/app/superuser/devicetypes`,
      icon: IconDevicesCode,
    },
    {
      id: 'locationtypes',
      title: 'Location Types',
      type: 'item',
      url: `/app/superuser/locationtypes`,
      icon: IconMapPin,
    },
  ];

  if (subscriber) {
    menuItem.push({
      id: 'subscribers',
      title: 'Subscribers',
      type: 'collapse',
      url: '/app/superuser/subscribers',
      icon: IconMessageDown,
      breadcrumbs: true,
      children: [
        {
          id: `subscriber-${subscriber.container_name || subscriber.sub_id}`,
          title: subscriber.container_name || `Subscriber ${subscriber.sub_id}`,
          type: 'item',
          url: `/app/superuser/subscriber/${subscriber.sub_id}`,
          icon: IconMessageDown,
          breadcrumbs: true,
        },
      ],
    });
  } else {
    menuItem.push({
      id: 'subscribers',
      title: 'Subscribers',
      type: 'item',
      url: '/app/superuser/subscribers',
      icon: IconMessageDown,
      breadcrumbs: true,
    });
  }

  return {
    id: 'superuser-group',
    title: 'Platform',
    type: 'group',
    children: menuItem,
  };
};

export default superuser;
