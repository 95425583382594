import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { PropTypes } from 'prop-types';

// import { accountGetOrganizations } from '../features/account/accountSlice';

export default function CheckAccess({ component: Component, access }) {
  // const dispatch = useDispatch();

  const { account } = useSelector((state) => state.account);

  // useEffect(() => {
  //   dispatch(accountGetOrganizations());
  // }, [dispatch]);
  const role = account ? account.role : null;

  const { orgID } = useParams();
  const isOrgAdmin = account.organizations.some((org) => org.org_id === parseInt(orgID, 10) && org.role === 'admin');
  const isOrgMember = account.organizations.some((org) => org.org_id === parseInt(orgID, 10) && org.role === 'member');

  if (role === 'superuser') {
    return <Component />;
  }

  if (orgID === 'superuser' || access === 'superuser') {
    return role === 'superuser' ? <Component /> : <Navigate to="/login" replace />;
  }

  if (access === 'all') {
    return <Component />;
  }

  if (access === 'admin') {
    if (isOrgAdmin) {
      return <Component />;
    }
  }
  if (access === 'member') {
    if (isOrgAdmin || isOrgMember) {
      return <Component />;
    }
  }
  if (access === 'user') {
    return <Component />;
  }

  return <Navigate to="/login" replace />;
}

CheckAccess.propTypes = {
  component: PropTypes.elementType.isRequired,
  access: PropTypes.string,
};
