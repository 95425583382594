// getMenuItemsData.js
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import admin from './admin';
import dashboard from './dashboard';
import superuser from './superuser';

export default function useMenuItemsData() {
  const { orgID, deviceID, locationID, subID } = useParams();
  const { account } = useSelector((state) => state.account);
  const { devices } = useSelector((state) => state.device);
  const { locations } = useSelector((state) => state.location);
  const { subscribers } = useSelector((state) => state.subscriber);

  const device = devices.find((device) => device.device_id === parseInt(deviceID, 10)) || null;
  const location = locations.find((location) => location.location_id === parseInt(locationID, 10)) || null;
  const subscriber = subID ? subscribers.find((subscriber) => subscriber.sub_id === parseInt(subID, 10)) || { sub_id: subID } : null;

  const items = [dashboard({ orgID })];

  const role = account ? account.role : null;
  const orgRole = account.organizations.find(({ org_id }) => org_id === parseInt(orgID, 10));

  if (role === 'superuser') {
    if (orgID === 'superuser') {
      items.push(superuser({ subscriber }));
    }
    items.push(admin({ orgID, location, device }));
  } else if (orgRole) {
    if (orgRole.role === 'admin') {
      items.push(admin({ orgID, location, device }));
    }
  }

  return { items };
}
